.header{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px; 
}

.header img{
    margin-top: 5vh;       
}
.logo{   
    height: 55px;
}

.interactiveLogo{
    width: 90%;
    max-width: 400px;
    height: auto;
}