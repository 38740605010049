body, html{
  height: 100%;
  font-family: 'Raleway', sans-serif;
}

.App {
  text-align: center;
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,1) 45%, rgba(185,215,234,1) 100%);
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;  
}

.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}