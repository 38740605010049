.sendIcon{
    width: 100%;
    margin: 5vh 0 5vh 0;
}

.sendIcon img{
    margin-bottom: 5vh;
}

.videoContainer{
    width: 100%;
    margin: 5vh 0 5vh 0;
}
.videoContainer img{
    width: inherit;
}

.videoContainer video{
    width: inherit;
    max-width: 1000px;
}
.lowerBox{
    background-color: red;
}

.btnContainer{
    width:  100%;
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}

button.btnDownload{
    display: block;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    font-size: 1.3em;
    color:white;
    text-decoration: none;
    background-color: transparent !important;
    background-image: url("../images/submit_button.png");
    background-repeat: no-repeat;
    background-size: 100%;
    border: none !important;   
    width: 280px;  
    height: 62px;    
    margin-top:8vh;    
}


button.btnDownload:visited, button.btnDownload:hover{
    color:white;
    text-decoration: none;
}

button.btnDownload:active{    
     background-image: url("../images/submit_button_down.png");
}

h1.purpleText{
    font-size: 2.8rem;
    color: rgb(91,67,204,1); 
}
.grayText{
    color: rgb(128,128,128,1); 
}